import React, { Component } from "react"
import LayoutDash from "../components/layout-dash"

import $ from "jquery"

export default class ServiceDash extends Component {
  constructor(props) {
    super(props)

    this.serviceType = "Hello"
    this.serviceArray = []
  }

  render() {
    return (
      <LayoutDash>
        <div className="dash-ui-main__left">
          <div className="dash-ui-main__left-1">
            <h1 className="dash-product-title">
              {"IA ".concat("> ").concat(this.serviceType)}
            </h1>
          </div>
          <div className="dash-ui-main__left-2 dash-subsc-box">
            <div className="dash-subsc-line-overlay"></div>
            <h1 className="dash-body-title">Charlie</h1>
            <h1 className="dash-body-subtitle">Active Subscription</h1>
            <div className="dash-spacing-vertical__title-line">
              <div className="dash-full-width-line"></div>
            </div>
            <div className="dash-subsc-row">
              <p>RailGuns Remaining:</p>
              <p>55/200</p>
            </div>
            <div className="dash-spacing-vertical__p-line">
              <div className="dash-full-width-line"></div>
            </div>
            <div className="dash-subsc-row">
              <p>CaseFiles Remaining:</p>
              <p>10/30</p>
            </div>
            <div className="dash-spacing-vertical__p-line">
              <div className="dash-full-width-line"></div>
            </div>
            <div className="dash-subsc-row">
              <p>GateCrashes Remaining:</p>
              <p>0/2</p>
            </div>
          </div>
        </div>
        <div className="dash-ui-main__right">
          <div className="dash-ui-main__right-1 dash-subsc-box">
            <div className="dash-subsc-line-overlay"></div>
            <div className="dash-subsc-line-overlay-large"></div>
            <h1 className="dash-body-title">Charlie</h1>
            <h1 className="dash-body-subtitle">Active Subscription</h1>
            <div className="dash-spacing-vertical__title-line">
              <div className="dash-full-width-line"></div>
            </div>

            {this.serviceArray.map((serviceCurrent, i) => {
              if (i + 1 !== this.serviceArray.length) {
                return (
                  <div>
                    <div className="dash-subsc-row">
                      <p> {serviceCurrent} </p>
                      <p>View File</p>
                    </div>
                    <div className="dash-spacing-vertical__p-line">
                      <div className="dash-full-width-line"></div>
                    </div>
                  </div>
                )
              } else {
                return (
                  <div>
                    <div className="dash-subsc-row">
                      <p> {serviceCurrent} </p>
                      <p>View File</p>
                    </div>
                  </div>
                )
              }
            })}

            {/* Alt Syntax */}
            {/*
              {caseFiles.map(caseFile => (
                <div>
                  <div className="subsc-row">
                    <p> {caseFile} </p>
                    <p>55/200</p>
                  </div>
                  <div className="spacing-vertical__p-line">
                    <div className="full-width-line"></div>
                  </div>
                </div>
              ))}
            */}
          </div>
        </div>
      </LayoutDash>
    )
  }

  uiAnimateLines() {
    if (typeof document !== `undefined`) {
      $(document).ready(() => {
        $(".full-width-line").animate({ width: "100%" }, 2400)
      })
    }

    /*
    $(document).ready(() => {
      $(".full-width-line").animate({ width: "100%" }, 2400)
    })
    */
  }
}
